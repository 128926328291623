@tailwind base;
@tailwind components;

.legaltech {
  --color-navy: #011d58;
  --color-hero: #011d58;
  --color-navy-menu: #ffffff;
  --color-navybright: #3329ef;
  --color-darkgray: #333333;
  --color-clouds: #f1f3f5;
  --color-mist: #dddddd;
  --color-shade: #767676;
  --color-desert-storm: #faf7f9;
  --color-porcelain: #f2f2f2;
  --color-rock-blue: #9cb6d3;
  --color-fog: #d4d4fc;
  --color-soft-peach: #eeeef1;
  --color-light-royal-blue: #332aef;
  --color-burly-wood: #e1b886;
  --color-light-greenish-blue: #69f3b6;
  --color-sand: #e9c46a;
  --color-blue-chalk: #ebeafe;
  --color-resolution-blue: #022d88;
  --color-light-grey: #d6dadf;
  --color-arty-click-ocean-green: #00ff8e;
  --color-bright-turquoise: #29e5ef;
  --color-warm-blue: #5951f0;
  --color-brandy: #f0c48f;
  --color-textlight: #011d58;
  --color-textdark: #011d58;

  --font-primary: 'Karla';
  --font-secondary: 'Poppins';
  --font-tertiary: 'Open Sans';
}

.ilta {
  --color-navy: #ffffff;
  --color-hero: #202a32;
  --color-navy-menu: #747678;
  --color-navybright: #a30034;
  --color-darkgray: #333333;
  --color-darkgray2: #3d4246;
  --color-clouds: #f1f1f1;
  --color-mist: #dddddd;
  --color-shade: #767676;
  --color-desert-storm: #faf7f9;
  --color-porcelain: #f2f2f2;
  --color-rock-blue: #9cb6d3;
  --color-fog: #d4d4fc;
  --color-soft-peach: #eeeef1;
  --color-light-royal-blue: #181f25;
  --color-burly-wood: #e1b886;
  --color-light-greenish-blue: #69f3b6;
  --color-sand: #e9c46a;
  --color-blue-chalk: #ebeafe;
  --color-resolution-blue: #022d88;
  --color-light-grey: #d6dadf;
  --color-arty-click-ocean-green: #00ff8e;
  --color-bright-turquoise: #29e5ef;
  --color-warm-blue: #5951f0;
  --color-brandy: #f0c48f;
  --color-textlight: #a30134;
  --color-textdark: #202a32;

  --font-primary: 'Montserrat';
  --font-secondary: 'Source Sans Pro';
  --font-tertiary: 'Open Sans';
}

@tailwind utilities;
